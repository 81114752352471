import { memo } from "react";
import PropTypes from "prop-types";
import "./ProductDestinationTitle.scss";
import classNames from "classnames";

const ProductDestinationTitleComponent = ({
	region,
	resort,
	className,
	children,
	shop,
	country,
}) => {
	let regionToDisplay = region;

	if (shop === "en-GB" && country === "France") {
		regionToDisplay = country;
	}

	return (
		<div className={classNames("product-destination-title", className)}>
			<span className="product-destination-title__region">{regionToDisplay}</span>
			{resort !== region && (
				<span className="product-destination-title__resort">{resort}</span>
			)}
			{children}
		</div>
	);
};

ProductDestinationTitleComponent.propTypes = {
	country: PropTypes.string,
	shop: PropTypes.string,
	region: PropTypes.string,
	resort: PropTypes.string,
	className: PropTypes.string,
};

export const ProductDestinationTitle = memo(ProductDestinationTitleComponent);
