import { memo } from "react";
import PropTypes from "prop-types";
import { BADGE_DEFAULT_COLORS } from "app/constants";

import "./ColoredProductBadge.scss";

const ColoredProductBadge = ({ label, colorStyle }) => {
	return label ? (
		<div
			className="colored-product-badge"
			style={{
				color: colorStyle.txtColor,
				backgroundColor: colorStyle.bgColor,
				border: colorStyle.borderColor && `1px solid ${colorStyle.borderColor}`,
			}}
		>
			{label}
		</div>
	) : null;
};

ColoredProductBadge.defaultProps = {
	colorStyle: {
		txtColor: BADGE_DEFAULT_COLORS.TEXT,
		bgColor: BADGE_DEFAULT_COLORS.BACKGROUND,
		borderColor: BADGE_DEFAULT_COLORS.BORDER,
	},
};

ColoredProductBadge.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	colorStyle: PropTypes.shape({
		txtColor: PropTypes.string,
		bgColor: PropTypes.string,
		borderColor: PropTypes.string,
	}),
};

export default memo(ColoredProductBadge);
