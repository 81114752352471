import { memo } from "react";
import PropTypes from "prop-types";
import { badgeShape } from "app/utils/propTypes";
import "./BadgeList.scss";
import ColoredProductBadge from "./ColoredProductBadge";

const BadgeList = ({ badges }) => {
	const containsValidBadge = badges.some(badge => badge.label);

	return (
		containsValidBadge && (
			<div className="badge-list">
				{badges.map(badge => {
					const { txtColor, bgColor, borderColor } = badge;
					const isColorDefined = badge.txtColor || badge.bgColor || badge.borderColor;
					const colorStyle = isColorDefined && {
						txtColor,
						bgColor,
						borderColor,
					};

					return (
						<div key={badge.label} className="badge-list__item">
							<ColoredProductBadge label={badge.label} colorStyle={colorStyle} />
						</div>
					);
				})}
			</div>
		)
	);
};

BadgeList.propTypes = {
	badges: PropTypes.arrayOf(badgeShape),
};

BadgeList.defaultProps = {
	badges: [],
};

export default memo(BadgeList);
