import ProductLink from "./ProductLink";
import { connect } from "react-redux";

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
		productsVisibility: state.productsVisibility,
		strategy: state.partner.strategy,
		source: state.marketing.source,
	};
};

export default connect(mapStateToProps)(ProductLink);
