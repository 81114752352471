import { FormattedMessage } from "react-intl";
import { OFFER_PRICE_TYPES } from "app/constants";
import From from "app/pages/FicheProduit/components/Deal/From.jsx";
import SaveUpTo from "app/pages/FicheProduit/components/Deal/SaveUpTo";

export const PriceType = ({
	type,
	value,
	includeOfferWithFlight,
	travelByTrainAvailable,
	duration,
	publicPrice,
	isRental,
}) => {
	switch (type) {
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO:
			return <SaveUpTo value={value} />;
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM:
			return (
				<From
					travelByTrainAvailable={travelByTrainAvailable}
					includeOfferWithFlight={includeOfferWithFlight}
					value={value}
					pricePrefix={<FormattedMessage id="general.from.label" />}
					priceSuffix={
						isRental ? (
							<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
						) : (
							<FormattedMessage id="general.amount.per.person.suffix.label.short" />
						)
					}
					duration={duration}
					publicPrice={publicPrice}
					publicPricePrefix={<FormattedMessage id="general.instead.of.price" />}
					offerPriceType={type}
					isRental={isRental}
				/>
			);
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT:
			return (
				<From
					travelByTrainAvailable={travelByTrainAvailable}
					includeOfferWithFlight={true}
					value={value}
					pricePrefix={<FormattedMessage id="general.from.label" />}
					priceSuffix={
						isRental ? (
							<FormattedMessage id="general.amount.per.accommodation.suffix.label.short" />
						) : (
							<FormattedMessage id="general.amount.per.person.suffix.label.short" />
						)
					}
					duration={duration}
					publicPrice={publicPrice}
					publicPricePrefix={<FormattedMessage id="general.instead.of.price" />}
					offerPriceType={type}
					isRental={isRental}
				/>
			);
		case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_EXCLUSIVE:
			return (
				<div className="product-deal__exclusive">
					<FormattedMessage id="general.exclusive.label" />
				</div>
			);
		default:
			return false;
	}
};
